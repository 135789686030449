//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
$primary: #d0ae81;


// //Typography
// $theme-dark-headings-color: #9f5324 !default;
// $theme-dark-label-color: #9f5324 !default;
// $theme-dark-text-muted-color: #6c3818 !default;
